<template>
  <div class="content">
    <div class="left">
      <div class="header">
        <el-form :inline="true" :model="form" class="demo-form-inline">
          <el-form-item>
            <el-input
              v-model="queryForm.customerId"
              size="small"
              style="width: 160px"
              placeholder="请输入客户ID"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="queryForm.customerName"
              size="small"
              style="width: 160px"
              placeholder="请输入客户名称"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item>
            <el-select
              size="small"
              style="width: 160px"
              v-model="queryForm.a"
              placeholder="请选择基础产品等级"
            >
            </el-select>
          </el-form-item> -->
          <el-form-item>
            <el-select
              v-model="queryForm.channelId"
              size="small"
              placeholder="请选择来源渠道"
              style="width: 160px"
            >
              <el-option label="全部" value=""></el-option>
              <el-option
                v-for="(item, index) in channelList"
                :key="index"
                :label="item.channelTypeName"
                :value="item.channelTypeId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="queryForm.regionId"
              size="small"
              placeholder="请选择大区"
              style="width: 160px"
              filterable
              @change="selectDistrict"
            >
              <el-option label="全部" value=""></el-option>
              <el-option
                v-for="(item, index) in regionList"
                :key="index"
                :value="item.regionId"
                :label="item.regionName"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="queryForm.provinceId"
              size="small"
              placeholder="请选择省份"
              style="width: 160px"
              filterable
              @change="selectCity"
            >
              <el-option label="全部" value=""></el-option>
              <el-option
                v-for="(item, index) in districtList"
                :key="index"
                :value="item.districtId"
                :label="item.districtName"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="queryForm.cityId"
              size="small"
              placeholder="请选择城市"
              style="width: 160px"
              filterable
              @change="selectCityId"
            >
              <el-option label="全部" value=""></el-option>
              <el-option
                v-for="(item, index) in cityList"
                :key="index"
                :value="item.districtId"
                :label="item.districtName"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-link :underline="false" type="primary" @click="visible = true"
              >点击选择标签</el-link
            >
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="onSubmit">查询</el-button>
            <el-button size="small" @click="reset">重置</el-button>
          </el-form-item>
          <br />
          <el-form-item>
            <el-tag
              v-for="tag in tags"
              :key="tag.name"
              size="small"
              closable
              :type="tag.type"
              @close="handleClose(tag)"
            >
              {{ tag.name }}
            </el-tag>
          </el-form-item>
        </el-form>
      </div>
      <div class="checkBox">
        <el-tree
          ref="tree"
          v-loading="loading"
          :data="customerList"
          show-checkbox
          default-expand-all
          :default-checked-keys="defaultChecked"
          node-key="customerId"
          highlight-current
          :props="defaultProps"
          style="min-height: 300px"
          @check-change="checkChange"
        >
        </el-tree>
        <!-- <el-checkbox-group
          style="min-height: 300px"
          v-model="checked"
          @change="handleChange"
        >
          <div class="item" v-for="(item, i) in customerList" :key="i">
            <el-checkbox :label="item"
              >{{ item.customertReqDto.customerId }}/{{
                item.customertReqDto.customerName
              }}/ -- /{{
                item.channelName ? item.channelName.split("/")[0] : "--"
              }}</el-checkbox
            ><br />
          </div>
        </el-checkbox-group> -->
        <Pages
          :total="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        />
      </div>
    </div>
    <div class="right">
      <div class="select">
        <span> 已选{{ checked.length }}/{{ selectTotal }}</span>
        <el-link :underline="false" type="primary" @click="clearData">清空</el-link>
      </div>
      <div style="flex: 1; overflow-y: scroll">
        <div v-for="(item, i) in checked" :key="i" class="text">
          <span class="text-left">
            {{ item.customerId }}/{{ item.customerName }}/
            {{ item.cityName ? item.cityName : '--' }}/
            {{ item.channelName ? item.channelName : '--' }}</span
          >
          <span class="close" @click="close(item)">x</span>
        </div>
      </div>
      <div class="footer">
        <el-button size="small" @click="cancel">取消</el-button>
        <el-button size="small" type="primary" @click="ok">保存</el-button>
      </div>
    </div>
    <setTagMangeVue
      :visible="visible"
      :value="tagIds"
      :tag-loading="tagLoading"
      @ok="tagOk"
      @cancel="tagCancel"
    />
  </div>
</template>

<script>
import {
  pageCustomer,
  getRegionList,
  getChannelTypeByLevelOne,
  districtQueryById,
  provinceListByRegionId,
} from '@/api'
import Pages from '@/components/pages'
import setTagMangeVue from '@/views/components/selectTags'

export default {
  components: { Pages, setTagMangeVue },
  props: {
    checkedData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: {
        pageSize: 10,
        pageNumber: 1,
        data: {},
      },
      queryForm: {
        channelId: undefined,
        regionId: undefined,
        provinceId: undefined,
        cityId: undefined,
      },
      visible: false,
      tags: [],
      checked: [],
      total: 0,
      selectTotal: 0,
      loading: false,
      customerList: [],
      defaultProps: {
        children: 'children',
        label: 'label',
      },
      defaultChecked: [],
      tagLoading: false,
      cityList: [],
      districtList: [],
      regionList: [],
      channelList: [],
      tagIds: [],
    }
  },
  mounted() {
    console.log(this.checkedData)
    getRegionList({ data: {} })
      .then((res) => {
        this.regionList = [...res.data]
      })
      .catch(() => {})
    getChannelTypeByLevelOne()
      .then((res) => {
        this.channelList = [...res.data]
      })
      .catch(() => {})
    if (this.checkedData && this.checkedData.length > 0) {
      this.defaultChecked = []
      this.checkedData.forEach((e) => {
        this.defaultChecked.push(e.customerId)
        this.checked.push({
          customerId: e.customerId,
          ...JSON.parse(e.content),
        })
      })
      console.log(this.checked)
    }
    this.loading = true
    pageCustomer(this.form).then((res) => {
      this.loading = false
      this.customerList = res.data.content
      this.customerList.forEach((e) => {
        e.customerId = e.customertReqDto.customerId
        e.customerName = e.customertReqDto.customerName
        e.label =
          e.customertReqDto.customerId +
          '/' +
          e.customertReqDto.customerName +
          '/' +
          (e.cityName ? e.cityName : '--') +
          '/' +
          (e.channelName ? e.channelName : '--')
      })
      this.total = Number(res.data.total)
      this.selectTotal = Number(res.data.total)
    })
  },
  methods: {
    clearData() {
      this.checked = []
      this.defaultChecked = []
      this.$refs.tree.setCheckedKeys([])
    },
    selectDistrict(e) {
      this.queryForm.provinceId = undefined
      this.queryForm.cityId = undefined
      if (e) {
        provinceListByRegionId({ data: e }).then((res) => {
          this.districtList = [...res.data]
        })
      }
    },
    selectCity(e) {
      this.queryForm.cityId = undefined
      if (e) {
        districtQueryById({
          data: {
            districtId: e,
            districtType: 2,
            status: 1,
          },
        }).then((res) => {
          this.cityList = [...res.data]
        })
      }
    },
    selectCityId(e) {
      this.queryForm.cityIdList = [e]
    },
    tagOk(e) {
      this.tags = [...e]
      this.visible = false
      this.tagIds = []
      this.tags.forEach((e) => {
        this.tagIds.push(e.id)
      })
      this.queryForm.tags = this.tagIds.join(',')
    },
    tagCancel() {
      this.visible = false
    },
    pageCustomer() {
      this.loading = true
      this.form.data = { ...this.queryForm }
      pageCustomer(this.form).then((res) => {
        this.loading = false
        this.customerList = res.data.content
        this.customerList.forEach((e) => {
          e.customerId = e.customertReqDto.customerId
          e.customerName = e.customertReqDto.customerName
          e.label =
            e.customertReqDto.customerId +
            '/' +
            e.customertReqDto.customerName +
            '/' +
            (e.cityName ? e.cityName : '--') +
            '/' +
            (e.channelName ? e.channelName : '--')
        })
        this.total = Number(res.data.total)
      })
    },
    handleSizeChange(val) {
      this.form.pageSize = val
      this.form.pageNumber = 1
      this.pageCustomer()
    },
    handleCurrentChange(val) {
      this.form.pageNumber = val
      this.pageCustomer()
    },
    filterArr(arr) {
      for (let i = 0; i < arr.length - 1; i++) {
        for (let j = i + 1; j < arr.length; j++) {
          if (arr[i].customerId === arr[j].customerId) {
            arr.splice(j, 1)
            j--
          }
        }
      }
      return arr
    },
    checkChange(e, n, f) {
      if (n) {
        this.checked.push(e)
        this.defaultChecked.push(e.customerId)
      } else {
        this.spliceData(e)
      }
    },
    spliceData(e) {
      for (let i = 0; i < this.checked.length; i++) {
        if (this.checked[i].customerId === e.customerId) {
          this.checked.splice(i, 1)
        }
      }
      for (let i = 0; i < this.defaultChecked.length; i++) {
        if (this.defaultChecked[i] === e.customerId) {
          this.defaultChecked.splice(i, 1)
        }
      }
    },
    close(row) {
      this.$refs.tree.setChecked(row.customerId)
      this.spliceData(row)
    },
    onSubmit() {
      this.form.pageNumber = 1
      this.pageCustomer()
    },
    reset() {
      this.tags = []
      this.tagIds = []
      this.form.pageNumber = 1
      this.queryForm = {
        channelId: undefined,
        regionId: undefined,
        provinceId: undefined,
        cityId: undefined,
      }
      this.form.data = {}
      this.pageCustomer()
    },
    handleChange() {},
    cancel() {
      this.$emit('customerOk')
    },
    ok() {
      this.$emit('customerOk', this.checked)
    },
  },
}
</script>
<style lang="less" scoped>
.el-tag {
  margin-right: 10px;
}
.select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 50px;
}
.content {
  display: flex;
  min-height: 100%;
  .left {
    flex: 1;
    border-right: 1px solid #eee;
    .checkBox {
      padding: 20px;
    }
    .item {
      padding: 4px;
    }
  }
  .right {
    padding: 20px;
    width: 360px;
    line-height: 30px;
    display: flex;
    flex-direction: column;
    .text {
      color: #666;
      display: flex;
      font-size: 14px;
      justify-content: space-between;
      padding-right: 20px;
      line-height: 24px;
      .text-left {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        flex: 1;
        width: 0;
      }
      .close {
        cursor: pointer;
      }
    }
  }
}
</style>
