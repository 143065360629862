<template>
  <el-dialog
    custom-class="set-tag-manage"
    title=""
    :visible.sync="dialogVisible"
    :append-to-body="appendToBody"
    :show-close="false"
    @close="cancel"
  >
    <el-row v-loading="tagLoading">
      <el-col :span="10">
        <div>
          <el-input
            v-model="filterText"
            prefix-icon="el-icon-search"
            placeholder="搜索标签名称"
          ></el-input>
        </div>

        <el-divider />

        <div class="tag-tree">
          <el-tree
            ref="tree"
            :data="data"
            node-key="keyId"
            default-expand-all
            :filter-node-method="filterNode"
            icon-class="el-icon-arrow-right"
          >
            <span slot-scope="scope" class="custom-tree-node">
              <span
                v-if="!scope.data.isTagParent"
                class="title"
                :style="scope.data.level == 1 ? 'font-weight:600' : ''"
                >{{ scope.data.name }}</span
              >

              <div v-else class="tags">
                <el-collapse :value="['1']">
                  <el-collapse-item :title="scope.data.name" name="1">
                    <div class="tagitems">
                      <div
                        v-for="(item, index) in scope.data.tagList"
                        v-show="
                          item.name.indexOf(filterText) !== -1 ||
                          scope.data.name.indexOf(filterText) !== -1
                        "
                        :key="index"
                        class="tag"
                        :class="item.isSelect ? 'tag-isSelect' : ''"
                        @click.stop="selectTag(item, data)"
                      >
                        {{ item.name }}

                        <img v-show="item.isSelect" class="icon" src="../../assets/tagClick.png" />
                      </div>
                    </div>
                  </el-collapse-item>
                </el-collapse>
              </div>
            </span>
          </el-tree>
        </div>
      </el-col>

      <el-col :span="14">
        <div
          style="
            padding: 10px;

            padding-top: 0;

            padding-left: 30px;

            height: 430px;
          "
        >
          <p style="margin-top: 0; font-weight: 600">已选({{ selectList.length }}/{{ total }})</p>

          <el-tag
            v-for="tag in selectList"
            :key="tag.keyId"
            style="margin-right: 10px; margin-bottom: 10px"
            :style="`color:#000;border:0;background-color:${tag.colour}`"
          >
            #{{ tag.name }}
          </el-tag>
        </div>

        <div style="text-align: right; margin-top: 15px">
          <el-button size="small" @click="cancel"> 取消 </el-button>

          <el-button size="small" type="primary" @click="ok"> 确认 </el-button>
        </div>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
import { getTagTree } from '@/api/index'

// import { colorRgba } from "@/utils/util";

// @ is an alias to /src

export default {
  name: 'SetTagManage',
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Object, Array],
      default: '',
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    tagLoading: {
      type: Boolean,
      default: false,
    },
    tagsData: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      data: [],

      filterText: '',

      selectList: [],
      selectTags: [],
      total: 0,

      dialogVisible: false,
    }
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    },

    visible(val) {
      this.selectList = []
      this.selectTags = this.value.map(String)
      this.selectTree(this.data)
      this.dialogVisible = val
    },
  },

  async created() {
    try {
      const response = await getTagTree({ data: { tagValueIds: [] } })
      const newData = [...response.data]
      this.changeTree(newData)
      this.data = newData
      // this.productRelationList = response.productRelationList;
    } catch (err) {}
  },
  mounted() {},

  methods: {
    ok() {
      this.$emit('ok', this.selectList)
    },

    cancel() {
      this.$emit('cancel')
    },

    filterNode(value, data) {
      if (data.tagList && data.tagList.filter((i) => i.name.indexOf(value) !== -1).length > 0) {
        return true
      }

      if (data.name.indexOf(value) !== -1) {
        return true
      }

      if (!value) return true

      return false

      //   return data.label.indexOf(value) !== -1;
    },

    selectTag(e, data) {
      if (e.isSelect === 1) {
        e.isSelect = 0

        this.selectList = this.selectList.filter((i) => {
          return i.keyId !== e.keyId
        })
      } else {
        if (data.isSingle === 1) {
          data.tagList.forEach((e) => {
            e.isSelect = 0
          })

          const keyIdList = data.tagList.map((i) => {
            return i.keyId
          })

          this.selectList = this.selectList.filter((i) => {
            return keyIdList.indexOf(i.keyId) === -1
          })

          e.isSelect = 1

          this.selectList.push(e)
        } else {
          e.isSelect = 1

          this.selectList.push(e)
        }
      }
    },

    selectTree(list) {
      list.forEach((e) => {
        if (e.children) {
          this.selectTree(e.children)
        }

        if (e.tagList) {
          for (let i = 0; i < e.tagList.length; i += 1) {
            const element = e.tagList[i]
            if (this.selectTags.indexOf(element.id) > -1) {
              element.isSelect = 1
              this.selectList.push(element)
            } else {
              element.isSelect = 0
            }
          }
        }
      })
    },

    changeTree(list) {
      list.forEach((e) => {
        if (e.children) {
          if (e.keyId.indexOf('T') === 0) {
            e.tagList = [...e.children]

            for (let index = 0; index < e.tagList.length; index += 1) {
              this.total += 1

              const item = e.tagList[index]

              if (item.isSelect === 1) {
                this.selectList.push(item)
              }
            }

            e.children = null

            e.open = true

            e.isTagParent = true
          } else {
            this.changeTree(e.children)
          }
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.tag-tree {
  height: 400px;

  overflow: auto;

  .custom-tree-node {
    height: auto;

    width: 100%;

    padding-right: 4px;

    .title {
      font-size: 14px;

      font-weight: 400;

      color: #000000;

      display: inline-block;

      padding: 3px 0;
    }

    .tags {
      height: auto;

      width: 100%;

      .title {
        width: 100%;

        display: flex;

        justify-content: space-between;

        i {
          z-index: 999 !important;
        }
      }
      .tagitems::after {
        content: '';

        clear: both;
      }

      .tag {
        padding: 0px 16px;

        height: 30px;

        border-radius: 4px;

        border: 1px solid #e0e0e0;

        text-align: center;

        line-height: 30px;

        display: inline-block;

        position: relative;

        margin: 4px 8px;

        .icon {
          position: absolute;

          right: 0px;

          bottom: 0;
        }
      }

      .tag:focus {
        background-color: #f5f7fa;
      }

      .tag-isSelect {
        border: 1px solid #2861e2;
      }
    }
  }
}

.tag-tree::-webkit-scrollbar {
  width: 5px;

  height: 10px;

  /**/
}

.tag-tree::-webkit-scrollbar-track {
  background: #fff;

  border-radius: 5px;

  display: none;
}

.tag-tree::-webkit-scrollbar-thumb {
  background: #bfbfbf;

  border-radius: 10px;
}
</style>

<style lang="less">
.set-tag-manage {
  .el-collapse-item__header {
    border: 0 !important;

    height: 32px !important;

    line-height: 32px !important;
  }

  .el-collapse {
    border: 0 !important;
  }

  .el-collapse-item__wrap {
    border: 0 !important;

    margin-bottom: 8px;
  }

  .el-dialog__header {
    padding: 0;
  }

  .el-tree-node__expand-icon {
    position: absolute;

    right: 0;
  }

  .el-tree-node__content {
    height: auto !important;

    background-color: #fff !important;
  }
}
</style>
