<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="700px"
    :before-close="handleClose"
    :show-close="false"
  >
    <div class="modal">
      <div class="tree">
        <el-input v-model="filterText" size="small" placeholder="搜索渠道名称"> </el-input>
        <el-tree
          ref="tree"
          v-loading="loading"
          style="min-height: 400px"
          node-key="id"
          :default-checked-keys="defaultChecked"
          :data="data"
          :filter-node-method="filterNode"
          :props="defaultProps"
          show-checkbox
          @check-change="checkChange"
        >
          <span slot-scope="scope" class="custom-tree-node">
            <span :style="{ fontWeight: scope.data.channelId ? '' : 'bold' }">{{
              scope.data.channelTypeName
            }}</span>
          </span>
        </el-tree>
      </div>
      <div class="right">
        <div>已选{{ checked.length }}/{{ total }}</div>
        <div style="flex: 1; overflow-y: scroll">
          <div v-for="(item, i) in checked" :key="i" class="text">
            <span> {{ item.channelName }}</span>
            <span class="close" @click="close(item)">x</span>
          </div>
        </div>
        <div class="footer">
          <el-button size="small" @click="cancel">取消</el-button>
          <el-button size="small" type="primary" @click="ok">保存</el-button>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { getEnbleChannelTree, getChannelPage } from '@/api'

export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    chanTags: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      defaultProps: {
        children: 'channelChildren',
        label: 'channelTypeName',
        isLeaf: 'leaf',
      },
      checked: [],
      filterText: '',
      visible: false,
      defaultChecked: [],
      loading: false,
      data: [],
      total: 0,
    }
  },
  watch: {
    dialogVisible(val) {
      this.filterText = ''
      if (val) {
        this.getTree()
        this.getChannelPage()
      } else {
        this.checked = []
        this.data = []
      }
    },
    filterText(val) {
      this.$refs.tree.filter(val)
    },
  },
  mounted() {},
  methods: {
    getChannelPage() {
      getChannelPage({
        data: {
          isDisable: 0,
        },
        pageNumber: 1,
        pageSize: 1,
      }).then((ress) => {
        this.total = ress.data.total
      })
    },
    checkChange() {
      const arr = this.$refs.tree.getCheckedNodes()
      const data = []
      this.checked = []

      arr.forEach((e) => {
        if (e.channelId) {
          data.push(e)
        }
      })
      this.checked = [...data]
    },
    close(row) {
      this.$refs.tree.setChecked(row.id)
    },
    getTree() {
      this.loading = true
      getEnbleChannelTree().then((res) => {
        this.loading = false
        this.data = res.data
        this.filterData(this.data)
        this.defaultChecked = []
        if (this.chanTags.length > 0) {
          this.checked = this.chanTags
          this.checked.forEach((e) => {
            this.defaultChecked.push(e.id)
          })
        }
      })
    },
    filterData(data) {
      data.forEach((e) => {
        if (e.channelResDtos && e.channelResDtos.length > 0) {
          e.channelResDtos.forEach((v) => {
            v.channelTypeName = v.channelName
            e.channelChildren.push(v)
          })
        }
        if (e.channelChildren && e.channelChildren.length > 0) {
          this.filterData(e.channelChildren)
        }
      })
    },
    filterNode(value, data) {
      if (!value) return true
      return data.channelTypeName.indexOf(value) !== -1
    },
    handleClose() {},
    cancel() {
      this.$emit('cancel')
    },
    ok() {
      console.log()
      const arr = this.$refs.tree.getCheckedNodes()
      const data = []
      arr.forEach((e) => {
        if (e.channelId) {
          data.push(e)
        }
      })
      this.$emit('ok', data)
    },
  },
}
</script>

<style lang="less" scoped>
.modal {
  display: flex;
  .tree {
    flex: 1;
    border-right: 1px solid #ddd;
    padding: 0 16px;
  }
  .right {
    flex: 1;
    padding: 0 16px;
    display: flex;
    flex-direction: column;
  }
  .text {
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
    line-height: 24px;
    .close {
      cursor: pointer;
    }
  }
}
</style>
